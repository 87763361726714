import "../style.css";
import { BrowserView, MobileView, } from 'react-device-detect';
import ModalImage from "react-modal-image";

export default function OneOne() {

  return (
    <div>
      <div className="container">


      </div>
      <div style={{ display: "flex", margin: "auto" }}>

        <BrowserView>
          <div style={{ paddingRight: "8%" }}>
            <table>
              <tr>
                <td>

                  <img src={require("./Images/1-1.jpg")} style={{ height: "600px" }}></img>

                </td>
                <h1 className="headline" style={{ paddingLeft: "25px", display: "flex" }}> Flächenbearbeitung</h1>
                <td style={{ paddingLeft: "25px", display: "flex" }}>
                  Mit der Flächenbearbeitung können Sie Aufträge für Mulcher-Einsätze detailliert planen und dem ausführenden Unternehmer zuteilen.

                  <br />

                </td>
                <tr>
                  <td style={{ width: "80%" }}>
                    <ul style={{ float: "left" }}>
                      <li>
                        Auftragsdaten
                      </li>
                      <li>
                        Auftraggeber,- und Nehmerinformationen
                      </li>
                      <li>
                        Preistabelle
                      </li>
                      <li>
                        Zeiterfassung
                      </li>
                      <li>
                        Mulcherspurplanung & Aufzeichnung
                      </li>
                      <li>
                        Auftragsfläche
                      </li>
                    </ul>
                  </td>
                </tr>
              </tr>

            </table>
          </div>
        </BrowserView>


        <MobileView>
          <table>
            <tr>

              <td>

                <img src={require("./Images/1-1.jpg")} style={{ height: "auto", width: "30%" }} ></img>


              </td>
            </tr>
            <tr>
              <td style={{ display: "flex", justifyContent: "left", width: "90%" }}>
                Mit der Flächenbearbeitung können Sie Aufträge für Mulcher-Einsätze detailliert planen und dem ausführenden Unternehmer zuteilen.

                <br />

              </td>
              <tr>
                <td style={{ display: "flex", justifyContent: "center", width: "70%" }}>
                  <ul>
                    <li>
                      Auftragsdaten
                    </li>
                    <li>
                      Auftraggeber,- und Nehmerinformationen
                    </li>
                    <li>
                      Preistabelle
                    </li>
                    <li>
                      Zeiterfassung
                    </li>
                    <li>
                      Mulcherspurplanung & Aufzeichnung
                    </li>
                    <li>
                      Auftragsfläche
                    </li>
                  </ul>
                </td>
              </tr>
            </tr>


          </table>
        </MobileView>
      </div>
    </div>
  );
}