import React, { Component } from 'react';
import Background from './Videos/Background.mp4';
import { useState } from 'react';
import L from "leaflet";
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import ScrollToTopNoDelay from './Router-Scripts/ScrollToTopNoDelay';
import MobileHome from './MobileHome';
import { BrowserView, MobileView, } from 'react-device-detect';

const iconPerson = new L.Icon({
  iconUrl: require('./images/Pin.png'),
  iconRetinaUrl: require('./images/Pin.png'),
  iconAnchor: null,
  popupAnchor: null,
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(40, 40),
  className: 'leaflet-div-icon'
});
export { iconPerson };


export default function Impressum() {
  const [toggle] = useState(false);


  window.addEventListener("scroll", function () {
  })


  return (
    <div>

      <section className="banner">
        <div className="container">


        </div>
      </section>
      <div className="Datenschutz">
        <br></br>
        <ScrollToTopNoDelay />

        <table>
          <tr>
            <td>

              <MobileView>
                <h1>IMPRESSUM</h1>
              </MobileView>
              <BrowserView>
                <h1 style={{ marginRight: "8%", fontSize: "50px" }}>IMPRESSUM</h1>
              </BrowserView>


              <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
              <p>Dietmar Dieing<br />
                Am Galgenb&uuml;hl 11<br />
                88316 Isny im Allg&auml;<br />
                Dieing GmbH | WOOD.IN.VISION®<br />
                Geschäftsführende Gesellschafter: Dietmar Dieing und Christoph Dieing<br />
                Amtsgericht: 89073 Ulm<br />
                Registernummer: HRB 747726</p>

              <h2>Kontakt</h2>
              <p>Telefon: +49 (0) 7562 912712<br />
                Telefax: +49 (0) 7562 9145074<br />
                E-Mail: info@wood-in-vision.com</p>

              <h2>Umsatzsteuer-ID</h2>
              <p>Steuernummer: 91060/35315</p>
              <p>Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect; 27 a Umsatzsteuergesetz:<br />
              DE367361926</p>

              <h2>Redaktionell verantwortlich</h2>
              <p>Christoph Dieing</p>

              <h2>EU-Streitschlichtung</h2>
              <p>Die Europ&auml;ische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="noopener noreferrer">https://ec.europa.eu/consumers/odr/</a>.<br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.</p>

              <h2>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h2>
              <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>

              <p>Quelle: <a href="https://www.e-recht24.de">e-recht24.de</a></p>
              <br></br>
              <p>Made by <a href="https://www.instagram.com/d3fx_films/">D3FX</a></p>
            </td>
            <td>

            </td>
          </tr>
        </table>
        <br></br>
        <MapContainer
          center={[47.70665, 10.05504]}
          zoom="30"
          scrollWheelZoom={false}
          className="markercluster-map"
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <link rel="stylesheet" href="https://unpkg.com/leaflet@1.0.1/dist/leaflet.css" />
          <Marker position={[47.70662, 10.05514]} icon={iconPerson}>
            <Popup>
              Wood.in.Vision <br /> Am Galgenbühl 11 <br />88316 Isny im Allgäu
            </Popup>
          </Marker>
        </MapContainer>
        <br></br>
        <br></br>

      </div>
    </div>

  )
}